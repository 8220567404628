<script setup lang="ts">
import Header from "~/components/layout/Header.vue";
import SearchModuleLoading from "~/components/cards/SearchModuleLoading.vue";
import TravelSearch from "~/components/searchModule/TravelSearch.vue";
import HeaderSearch from "~/components/layout/HeaderSearch.vue";
import {breakpointsTailwind} from "@vueuse/core";
const img = useImage();

const breakpoints = useBreakpoints(breakpointsTailwind)

const largerThanSm = breakpoints.greater('sm');

const backgroundStyles = computed(() => {
    const imgUrl = img('/images/bg-image2.png', { width: 1999, height:290, quality: 70, format: 'webp' })
    return { backgroundImage: `url('${imgUrl}')` }
})
</script>

<template>
    <div>
        <Header class="hidden sm:flex"/>
        <div class="flex flex-col justify-center items-center">
            <div class="bg-right-bottom bg-cover pt-24 pb-10 px-3.5 sm:px-5 lg:px-10 w-full min-h-[80px] sm:min-h-[286px] absolute sm:relative top-0" :style="backgroundStyles">
                <div class="bg-gradient-to-r from-black/20 absolute w-full h-full left-0 top-0 z-0 hidden sm:block"/>
                <div class="bg-gradient-to-t from-gray-lighter from-25% absolute w-full h-full left-0 top-0 z-0 sm:hidden"/>
                <div class="hidden sm:flex flex-col items-center gap-10 justify-center">
                    <client-only>
                        <TravelSearch class="max-w-page w-full" v-if="largerThanSm"/>
                        <template #placeholder>
                            <SearchModuleLoading class="max-w-page"/>
                        </template>
                    </client-only>
                </div>
                <client-only>
                    <div v-if="!largerThanSm">
                        <HeaderSearch class="block sm:hidden"/>
                    </div>
                </client-only>
            </div>
        </div>
        <div class="mt-20 sm:mt-0 relative">
            <slot/>
        </div>
    </div>
</template>

<style scoped>

</style>
