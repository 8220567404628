<script setup lang="ts">
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import TravelSearch from "~/components/searchModule/TravelSearch.vue";

const mainStore = useMainStore();
const {displayNights, formatDateSQ} = useUtils();
const showSearchModule = ref(false);
const headerSearch = ref(null);


const {currSearchParams} = storeToRefs(mainStore)

onClickOutside(headerSearch, () => {
    showSearchModule.value = false;
})

const travelPlaces = computed(() => {
    return `${currSearchParams.value?.origin || '...'} - ${currSearchParams.value?.destination || '...'}`;
})

const travelingDate = computed(() => {
    return `${formatDateSQ(currSearchParams.value?.date)|| 'Loading'} - ${displayNights(currSearchParams.value?.nights)|| 'Loading'}`;
})

const handleBack = () => {
    console.log('handleee baccck')
    showSearchModule.value = false
}
</script>

<template>
    <div :class="['fixed w-full bg-black/40 z-20 top-0 left-0 select-none', {'h-[100dvh] bg-black/50': showSearchModule}]">
        <div ref="headerSearch">
            <div @click="showSearchModule = true" :class="['flex flex-col items-center justify-center py-5 relative w-full border-b border-white/20 text-white font-bold',
             {'cursor-pointer': !showSearchModule}, {'bg-secondary/60': showSearchModule}]">
                <transition name="fade" mode="out-in">
                    <div class="text-center cursor-pointer" :key="travelPlaces + travelingDate">
                        {{ travelPlaces }}
                        <br>
                        {{travelingDate}}
                    </div>
                </transition>
            </div>
            <div :class="[{'hidden': !showSearchModule},'px-5 pt-5']">
                <TravelSearch @search="handleBack" :class="['w-full']"/>
            </div>
            <div class="text-xl absolute right-5 py-2 px-3 top-5 text-white">
                <font-awesome-icon :icon="['fas', 'magnifying-glass']"/>
            </div>
        </div>
        <transition name="fade">
            <div v-if="showSearchModule" class="py-2 px-3 text-xl absolute left-5 top-5 cursor-pointer text-white">
                <font-awesome-icon :icon="['fas', 'chevron-left']"/>
            </div>
        </transition>
    </div>
</template>

<style scoped>

</style>
